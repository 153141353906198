import BaseBean from "@/utils/BaseBean";

export interface IZoneListDataObj {
    utilInst:ZoneListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
}

export default class ZoneListUtil extends BaseBean{
    public dataObj:IZoneListDataObj

    constructor(proxy:any,dataObj:IZoneListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}