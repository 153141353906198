export default {
    zh:{
        zone:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                fzr:'负责人',
                fzr_placeholder:'请输入负责人',
                contact:'联系电话',
                contact_placeholder:'请输入联系电话',
            },
            columns:{
                F_NAME:'名称',
                F_FZR:'负责人',
                F_CONTACT:'联系电话',
                F_COUNTRY_ID:'管理国家',
                F_PARENT_ID:'上级区域',
            },
            form:{
                name:'区域名称',
                name_placeholder:'请输入区域名称',
                name_rule: '请输入区域名称',
                fzr:'区域负责人',
                fzr_placeholder:'请输入区域负责人',
                contact:'负责人电话',
                contact_placeholder:'请输入负责人电话',
                parentId:'上级区域',
                parentId_placeholder:'请选择上级区域',
                countryId:'管理国家',
                countryId_placeholder:'请选择管理国家',
                countryId_rule: '请选择管理国家'
            },
        }
    },
    en:{
        zone:{
            queryParam:{
                name:'名称',
                name_placeholder:'请输入名称',
                fzr:'负责人',
                fzr_placeholder:'请输入负责人',
                contact:'联系电话',
                contact_placeholder:'请输入联系电话',
            },
            columns:{
                F_NAME:'名称',
                F_FZR:'负责人',
                F_CONTACT:'联系电话',
                F_COUNTRY_ID:'管理国家',
                F_PARENT_ID:'上级区域',
            },
            form:{
                name:'名称',
                name_placeholder:'请输入名称',
                name_rule: '请输入名称',
                fzr:'负责人',
                fzr_placeholder:'请输入负责人',
                contact:'联系电话',
                contact_placeholder:'请输入联系电话',
                parentId:'上级区域',
                parentId_placeholder:'请选择上级区域',
                countryId:'管理国家',
                countryId_placeholder:'请选择管理国家',
                countryId_rule: '请选择管理国家'
            },
        }
    }
}