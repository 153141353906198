import ZoneCard from './ZoneCard.vue';
import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance, watch, provide,nextTick} from 'vue';
import ZoneListUtil,{IZoneListDataObj} from './zoneListUtil';
import language from './zoneLanguage'
export default defineComponent ({
    name: 'ZoneList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IZoneListDataObj=reactive<IZoneListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {},
                modelComp: ZoneCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/zone/pageData')
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ZoneListUtil(proxy,dataObj);
        })
        onMounted(()=>{
        })
        //---------------------------computed------------

        const formatPageInfo=(options:any)=>{
            //找到需要格式化的列
            //options.pageInfo.columns.forEach((item:any)=>{
            //    if(item.prop=='F_DY_FLAG')item.formatter=utils.UtilPub.formatterYesNoStatus;
            //})
            return options;
        }

        return{
            ...toRefs(dataObj),formatPageInfo
        }
    }
});